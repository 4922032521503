
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="No. Usulan">
                  <CInput
                    label="No. Usulan"
                    placeholder="No. Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="Tanggal Usulan">
                  <div role="group" class="form-group">
                    <label class>Tanggal Usulan</label>
                    <datepicker
                      placeholder="Tanggal Usulan"
                      v-model="form.tgl_surat"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
              <!-- <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Lampiran"
                >
                  <CInput
                    label="Lampiran"
                    placeholder="Lampiran"
                    v-model="form.keterangan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>-->
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Instansi"
                  placeholder="Instansi"
                  :value="'PURWAKARTA'"
                  :readonly="true"
                ></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>Unit Kerja</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsUnitKerja"
                      label="nama_unit_kerja"
                      :reduce="(unit_kerja) => unit_kerja.id_unit_kerja"
                      :filterable="false"
                      @search="onSearchUnitKerja"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol>
                <CCard accentColor="primary">
                  <CCardHeader>
                    <h6>Tembusan</h6>
                    <CCol>
                      <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                        <CButton
                          class="col-md-2"
                          color="info"
                          @click="addTembusan"
                        >
                          <CIcon name="cil-plus" />Tambah Tembusan
                        </CButton>
                      </CButtonGroup>
                    </CCol>
                  </CCardHeader>
                  <CCardBody>
                    <div class="position-relative table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th
                              class
                              style="vertical-align: middle; overflow: hidden"
                            >
                              <div>No</div>
                            </th>
                            <th
                              class
                              style="vertical-align: middle; overflow: hidden"
                            >
                              <div>Tembusan</div>
                            </th>
                            <th
                              class
                              style="vertical-align: middle; overflow: hidden"
                            ></th>
                          </tr>
                        </thead>
                        <tbody class="position-relative">
                          <template
                            v-for="(tembusan, index_tembusan) in form.tembusan"
                          >
                            <tr
                              :key="index_tembusan"
                              v-if="tembusan.is_deleted == 0"
                            >
                              <td style="width: 10%">
                                {{ index_tembusan + 1 }}
                              </td>
                              <td>
                                <input
                                  v-model="tembusan.tembusan"
                                  type="text"
                                  placeholder
                                  class="form-control"
                                />
                              </td>
                              <td style="width: 10%">
                                <CButtonGroup size="sm">
                                  <CButton
                                    color="danger"
                                    class="px-4"
                                    @click="deleteTembusan(index_tembusan)"
                                    >Hapus</CButton
                                  >
                                </CButtonGroup>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>-->

            <CRow>
              <CCol>
                <CCard accentColor="success">
                  <CCardHeader>
                    <h6>Pegawai</h6>
                    <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                      <CButton class="col-md-2" color="info" @click="addDetails">
                        <CIcon name="cil-plus" />Tambah Pegawai
                      </CButton>
                    </CButtonGroup>
                  </CCardHeader>
                  <CCardBody>
                    <template v-for="(data, index) in form.details">
                      <fieldset class="form-group p-3" :key="index" v-if="data.is_deleted == 0">
                        <div small style :key="componentKey">
                          <CRow>
                            <CCol>
                              <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                                <CButton
                                  class="col-md-1"
                                  color="danger"
                                  @click="deleteDetails(index)"
                                >Hapus</CButton>
                              </CButtonGroup>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                                name="NIP - Nama"
                              >
                                <div role="group" class="form-group">
                                  <label class>NIP - Nama</label>
                                  <v-select
                                    :key="'select_' + componentKey + index"
                                    v-model="data.petugas_array"
                                    :options="data.optionsPgw"
                                    label="nama"
                                    :filterable="false"
                                    @search="onSearch(index, $event)"
                                    @input="onChangesPegawai(index, $event)"
                                    :class="[
                                      { 'is-valid': !errors[0] },
                                      { 'is-invalid': errors[0] },
                                    ]"
                                  ></v-select>
                                  <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                                </div>
                              </validation-provider>
                            </CCol>
                            <CCol md="6">
                              <validation-provider
                                rules="required"
                                v-slot="{ errors }"
                                name="Instansi"
                              >
                                <div role="group" class="form-group">
                                  <label class>Instansi Tujuan</label>
                                  <v-select
                                    v-model="data.id_instansi_tujuan"
                                    item-text="nama_instansi"
                                    item-value="id_instansi_tujuan"
                                    :reduce="(instansi) => instansi.id_instansi"
                                    :options="data.optionsInstansiTujuan"
                                    label="nama_instansi"
                                    @search="
                                      onSearchInstansiTujuan(index, $event)
                                    "
                                    :class="[
                                      { 'is-valid': !errors[0] },
                                      { 'is-invalid': errors[0] },
                                    ]"
                                  ></v-select>
                                  <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                                </div>
                              </validation-provider>
                            </CCol>
                          </CRow>
                          <!---->
                          <fieldset class="form-group border p-3" v-if="data.informasi_pegawai">
                            <legend class="w-auto px-2">
                              <h6>Informasi Pegawai</h6>
                            </legend>
                            <CRow>
                              <CCol md="6">
                                <CInput
                                  label="Jenis Kelamin"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.jenis_kelamin"
                                ></CInput>
                                <CInput
                                  label="Jabatan"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.nama_jabatan"
                                ></CInput>
                                <CInput
                                  label="Nama Unit Kerja"
                                  placeholder
                                  readonly
                                  v-model="
                                    data.informasi_pegawai.nama_unit_kerja
                                  "
                                ></CInput>
                                <CInput
                                  label="Golongan Ruang"
                                  placeholder
                                  readonly
                                  v-model="
                                    data.informasi_pegawai
                                      .pangkat_golongan_ruang
                                  "
                                ></CInput>
                              </CCol>
                              <CCol md="6">
                                <CInput
                                  label="Gaji Pokok"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.gaji_pokok"
                                ></CInput>
                                <CInput
                                  label="TMT Pangkat"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.tmt_pangkat"
                                ></CInput>
                                <CInput
                                  label="Masa Jabatan"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.masa_jabatan"
                                ></CInput>
                                <CInput
                                  label="Umur"
                                  placeholder
                                  readonly
                                  v-model="data.informasi_pegawai.umur"
                                ></CInput>
                              </CCol>
                            </CRow>
                          </fieldset>
                          <!---->
                          <fieldset class="form-group border p-3" v-if="data.informasi_pegawai">
                            <legend class="w-auto px-2">
                              <h6>Informasi Jabatan Mutasi</h6>
                            </legend>
                            <CRow>
                              <CCol md="6">
                                <CSelect
                                  label="Jenis Jabatan"
                                  :value.sync="
                                    data.jabatan_mutasi.id_jenis_jabatan
                                  "
                                  :options="[
                                    { value: 1, label: 'STRUKTURAL' },
                                    { value: 4, label: 'FUNGSIONAL UMUM' },
                                  ]"
                                />
                              </CCol>
                              <CCol md="6" v-if="data.jabatan_mutasi.id_jenis_jabatan == 1">
                                <validation-provider
                                  rules="required"
                                  v-slot="{ errors }"
                                  name="Jabatan Stuktural"
                                >
                                  <div role="group" class="form-group">
                                    <label class>Jabatan Stuktural</label>
                                    <v-select
                                      v-model="data.jabatan_mutasi.id_jabatan"
                                      :options="
                                        data.jabatan_mutasi.optionsJabatanUmum
                                      "
                                      label="nama_jabatan"
                                      :reduce="
                                        (jabatan_umum) => jabatan_umum.id
                                      "
                                      :filterable="false"
                                      @search="
                                        onSearchJabatanUmum(index, $event)
                                      "
                                      :class="[
                                        { 'is-valid': !errors[0] },
                                        { 'is-invalid': errors[0] },
                                      ]"
                                    ></v-select>
                                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                                  </div>
                                </validation-provider>
                              </CCol>
                              <CCol md="6" v-if="data.jabatan_mutasi.id_jenis_jabatan == 4">
                                <validation-provider
                                  rules="required"
                                  v-slot="{ errors }"
                                  name="Jabatan Fungsional"
                                >
                                  <div role="group" class="form-group">
                                    <label class>Jabatan Fungsional</label>
                                    <v-select
                                      v-model="data.jabatan_mutasi.id_jabatan"
                                      :options="
                                        data.jabatan_mutasi
                                          .optionsJabatanFungsional
                                      "
                                      label="nama_jabatan"
                                      :reduce="
                                        (jabatan_fungsional) =>
                                          jabatan_fungsional.id_jabatan_fungsional
                                      "
                                      :filterable="false"
                                      @search="
                                        onSearchJabatanFungsional(index, $event)
                                      "
                                      :class="[
                                        { 'is-valid': !errors[0] },
                                        { 'is-invalid': errors[0] },
                                      ]"
                                    ></v-select>
                                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                                  </div>
                                </validation-provider>
                              </CCol>
                              <CCol md="6">
                                <div role="group" class="form-group">
                                  <label class>Jenis Guru</label>
                                  <v-select
                                    v-model="data.jabatan_mutasi.id_jenis_guru"
                                    :options="
                                      data.jabatan_mutasi.optionsJnsGuru
                                    "
                                    label="nama"
                                    :reduce="(x) => x.id_jenis_guru"
                                    :filterable="false"
                                    @search="onSearchJenisGuru(index, $event)"
                                  ></v-select>
                                </div>
                              </CCol>
                              <CCol md="6">
                                <validation-provider
                                  rules="required"
                                  v-slot="{ errors }"
                                  name="Unit Kerja"
                                >
                                  <div role="group" class="form-group">
                                    <label class>Unit Kerja</label>
                                    <v-select
                                      v-model="
                                        data.jabatan_mutasi.id_unit_kerja
                                      "
                                      :options="
                                        data.jabatan_mutasi.optionsUnitKerja
                                      "
                                      label="nama_unit_kerja"
                                      :reduce="
                                        (unit_kerja) => unit_kerja.id_unit_kerja
                                      "
                                      :filterable="false"
                                      @search="
                                        onSearchUnitKerjaMutasi(index, $event)
                                      "
                                      :class="[
                                        { 'is-valid': !errors[0] },
                                        { 'is-invalid': errors[0] },
                                      ]"
                                    ></v-select>
                                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                                  </div>
                                </validation-provider>
                              </CCol>
                            </CRow>
                          </fieldset>
                          <!---->
                          <fieldset class="form-group border p-3" v-if="data.informasi_pegawai">
                            <legend class="w-auto px-2">
                              <h6>Alasan Mutasi Jabatan</h6>
                            </legend>
                            <CRow>
                              <CCol>
                                <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                                  <CButton class="col-md-2" color="info" @click="addAlasan(index)">
                                    <CIcon name="cil-plus" />Tambah Alasan
                                  </CButton>
                                </CButtonGroup>
                              </CCol>
                            </CRow>
                            <hr />
                            <CRow>
                              <CCol md="12">
                                <div class="position-relative table-responsive">
                                  <table class="table table-hover table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          class
                                          style="
                                            vertical-align: middle;
                                            overflow: hidden;
                                          "
                                        >
                                          <div>No</div>
                                          <!---->
                                        </th>
                                        <th
                                          class
                                          style="
                                            vertical-align: middle;
                                            overflow: hidden;
                                          "
                                        >
                                          <div>Alasan</div>
                                          <!---->
                                        </th>
                                        <th
                                          class
                                          style="
                                            vertical-align: middle;
                                            overflow: hidden;
                                          "
                                        ></th>
                                      </tr>
                                      <!---->
                                    </thead>
                                    <tbody class="position-relative">
                                      <template
                                        v-for="(
                                          alasan, index_alasan
                                        ) in data.alasan_mutasi"
                                      >
                                        <tr :key="index_alasan" v-if="alasan.is_deleted == 0">
                                          <td style="width: 10%">{{ index_alasan + 1 }}</td>
                                          <td>
                                            <input
                                              v-model="alasan.alasan"
                                              type="text"
                                              placeholder
                                              class="form-control"
                                            />
                                          </td>
                                          <td style="width: 10%">
                                            <CButtonGroup size="sm">
                                              <CButton
                                                color="danger"
                                                class="px-4"
                                                @click="
                                                  deleteAlasan(
                                                    index,
                                                    index_alasan
                                                  )
                                                "
                                              >Hapus</CButton>
                                            </CButtonGroup>
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                    <!---->
                                  </table>
                                  <!---->
                                </div>
                              </CCol>
                            </CRow>
                          </fieldset>
                          <div class="position-relative table-responsive">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    class
                                    style="
                                      vertical-align: middle;
                                      overflow: hidden;
                                    "
                                  >
                                    <div>No</div>
                                    <!---->
                                  </th>
                                  <th
                                    class
                                    style="
                                      vertical-align: middle;
                                      overflow: hidden;
                                    "
                                  >
                                    <div>Jenis Dokumen</div>
                                    <!---->
                                  </th>
                                  <th
                                    class
                                    style="
                                      vertical-align: middle;
                                      overflow: hidden;
                                    "
                                  >
                                    <div>Format File</div>
                                    <!---->
                                  </th>
                                  <th
                                    class
                                    style="
                                      vertical-align: middle;
                                      overflow: hidden;
                                    "
                                  >
                                    <div>File Upload</div>
                                    <!---->
                                  </th>
                                </tr>
                                <!---->
                              </thead>
                              <tbody class="position-relative">
                                <tr
                                  v-for="(
                                    dataUpload, index2
                                  ) in data.upload_detail"
                                  :key="index2"
                                >
                                  <td>{{ index2 + 1 }}</td>
                                  <td>{{ dataUpload.nama_dokumen }}</td>
                                  <td>{{ dataUpload.format_file }}</td>
                                  <td style="width: 20%">
                                    <CInputFile
                                      label="File input"
                                      @change="
                                        uploadFile($event, index, index2)
                                      "
                                      :isValid="true"
                                      custom
                                      v-if="
                                        !dataUpload.upload_dokumen &&
                                        !dataUpload.loadingFile
                                      "
                                    />
                                    <CSpinner
                                      color="success"
                                      size="sm"
                                      v-if="dataUpload.loadingFile"
                                    />
                                    <CButtonGroup size="sm" v-if="dataUpload.upload_dokumen">
                                      <CButton
                                        color="success"
                                        class="px-4"
                                        @click="downloadFile(index, index2)"
                                      >Download File</CButton>
                                      <CButton
                                        color="danger"
                                        class="px-4"
                                        @click="deleteFile(index, index2)"
                                      >Hapus File</CButton>
                                    </CButtonGroup>
                                  </td>
                                </tr>
                              </tbody>
                              <!---->
                            </table>
                            <!---->
                          </div>
                          <!---->
                        </div>
                      </fieldset>
                    </template>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
              <CCol col="6" class="text-right">
                <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                <CButton color="link" class="d-lg-none">Register now!</CButton>-->
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import VSelect from "vue-select";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import axios from "axios";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "FormUsulanKenaikanPangkat",
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Datepicker,
  },
  data() {
    return {
      errorMessage: null,
      optionsPgw: [],
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJenisLayanan: [],
      form: {
        id: null,
        id_instansi: "3214",
        id_unit_kerja: null,
        no_surat: null,
        tgl_surat: null,
        details: [],
        tembusan: [],
        keterangan: null,
      },
      loading: false,
      item: null,
      componentKey: 0,
    };
  },
  computed: {},
  mounted() { },
  methods: {
    async uploadFile(files, index, index2) {
      const file = new FormData();
      file.append("files", files[0]);
      file.append("path", "dokumen-pegawai");
      file.append(
        "name_file",
        this.form.details[index]["upload_detail"][index2]["nama_dokumen"] +
        "_" +
        this.form.details[index]["nip"]
      );
      try {
        this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
        this.componentKey += 1;
        let { data } = await axios.post("/api/upload-document", file);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          data.url;
      } catch (error) {
        console.log(error);
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          null;
      } finally {
        this.form.details[index]["upload_detail"][index2][
          "loadingFile"
        ] = false;
        this.componentKey += 1;
      }
    },
    downloadFile(index, index2) {
      window.open(
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"],
        "_blank"
      );
    },
    deleteFile(index, index2) {
      this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
        null;
    },
    async onSearch(index, search) {
      // loading(true);
      await this.search(index, search, this);
      // loading(false);
    },
    search: _.debounce(async (index, search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete",
        search
      );
      if (search) {
        requestAnimationFrame(() => {
          vm.form.details[index]["optionsPgw"] = options;
        });
      }
    }, 300),
    async searchLayanan(search) {
      let options = await this.$store.dispatch(
        "jenis_layanan/autocomplete2",
        search
      );
      this.optionsJenisLayanan = options;
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        var form = { ...this.form };
        form.details.forEach((value) => {
          value.petugas_array = null;
          value.optionsPgw = null;
          value.informasi_pegawai = null;
        });
        let { status } = vm.form.id
          ? await this.$store.dispatch("pelayanan/update", {
            data: form,
            id: vm.form.id,
          })
          : await this.$store.dispatch("pelayanan/store", form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    showModalFormPilih(item) {
      this.$refs.formPilih.toggle(item, this.item);
    },
    populateInput(item) {
      this.searchLayanan("", this);
      this.searchInstansi("purwakarta", this);
      this.form.id = null;
      this.form.keterangan = null;
      this.form.id_instansi = "3214";
      this.form.id_unit_kerja = null;
      this.form.no_surat = null;
      this.form.tgl_surat = null;
      this.optionsInstansi = [];
      this.optionsUnitKerja = [];
      this.form.details = [];
      console.log(item);
      if (item) {
        item.tembusan_pelayanan.forEach((element) => {
          this.form.tembusan.push({
            id: element.id,
            tembusan: element.tembusan,
            is_deleted: 0,
          });
        });

        this.form.id = item.id;
        this.form.keterangan = item.keterangan;
        this.form.id_instansi = item.id_instansi.toString();
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.no_surat = item.no_surat;
        this.form.tgl_surat = item.tgl_surat;
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        const array2 = [];
        array2.push(item.mst_unit_kerja);
        this.optionsUnitKerja = array2;

        item.pelayanan_detail.forEach((element) => {
          const arrayPegawai = [];
          arrayPegawai.push({
            nip: element.d_identitas.nip,
            nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
          });

          const array3 = [];

          // element.pegawai_mutasi_masuk.mst_jabatan_fungsional.id_jabatan_fungsional = parseFloat(
          //   element.pegawai_mutasi_masuk.mst_jabatan_fungsional.id_jabatan_fungsional
          // );
          array3.push(element.pegawai_mutasi_masuk.mst_jabatan_fungsional);

          const array4 = [];
          array4.push(element.pegawai_mutasi_masuk.mst_jabatan_umum);

          const array5 = [];
          array5.push(element.pegawai_mutasi_masuk.mst_unit_kerja);

          const array6 = [];
          if (element.pegawai_mutasi_masuk.mst_jenis_guru)
            array6.push({
              nama:
                element.pegawai_mutasi_masuk.mst_jenis_guru.id_jenis_guru +
                " - " +
                element.pegawai_mutasi_masuk.mst_jenis_guru.nama_jenis_guru,
              id_jenis_guru:
                element.pegawai_mutasi_masuk.mst_jenis_guru.id_jenis_guru,
            });

          const alasanContainer = [];
          element.alasan_mutasi_masuk.forEach((alasan) => {
            alasanContainer.push({
              id: alasan.id,
              alasan: alasan.alasan,
              is_deleted: 0,
            });
          });

          const array7 = [];
          array7.push(item.detail_mutasi_masuk.mst_instansi);

          this.form.details.push({
            id: element.id,
            nip: element.nip,
            ref_jenis_layanan_id: 6,
            ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
            upload_file: element.upload_file,
            jenis_pelayanan_array: [],
            petugas_array: arrayPegawai,
            optionsPgw: arrayPegawai,
            informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
            upload_detail: element.pelayanan_detail_upload,
            id_instansi_tujuan:
              item.detail_mutasi_masuk.id_instansi_tujuan.toString(),
            optionsInstansiTujuan: array7,
            jabatan_mutasi: {
              nip: element.nip,
              id_jenis_jabatan: element.pegawai_mutasi_masuk.id_jenis_jabatan,
              id_jabatan: parseInt(element.pegawai_mutasi_masuk.id_jabatan),
              id_jenis_guru: element.pegawai_mutasi_masuk.id_jenis_guru,
              id_unit_kerja: element.pegawai_mutasi_masuk.id_unit_kerja,
              optionsJabatanUmum: array4,
              optionsJabatanFungsional: array3,
              optionsJnsGuru: array6,
              optionsUnitKerja: array5,
            },
            alasan_mutasi: alasanContainer,
            is_deleted: 0,
          });
        });
      }
    },
    async onSearchInstansiTujuan(index, search) {
      let options = await this.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) this.form.details[index]["optionsInstansiTujuan"] = options;
    },
    async onSearchInstansi(search, loading) {
      loading(true);
      await this.searchInstansi(search, this);
      loading(false);
    },
    searchInstansi: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      // if (search)
      vm.optionsInstansi = options;
    }, 300),
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: "",
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    async onSearchUnitKerjaMutasi(index, search) {
      // loading(true);
      await this.searchUnitKerjaMutasi(search, this, index);
      // loading(false);
    },
    searchUnitKerjaMutasi: _.debounce(async (search, vm, index) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: "",
      });
      if (search)
        vm.form.details[index]["jabatan_mutasi"]["optionsUnitKerja"] = options;
    }, 300),
    addDetails() {
      this.form.details.push({
        id: null,
        nip: null,
        ref_jenis_layanan_id: 6,
        ref_persyaratan_pelayanan_id: null,
        upload_file: null,
        jenis_pelayanan_array: [],
        petugas_array: [],
        optionsPgw: [],
        informasi_pegawai: null,
        upload_detail: [],
        id_instansi_tujuan: null,
        optionsInstansiTujuan: [],
        jabatan_mutasi: {
          nip: null,
          id_jenis_jabatan: 1,
          id_jabatan: null,
          id_jenis_guru: null,
          id_unit_kerja: null,
          optionsJabatanUmum: [],
          optionsJabatanFungsional: [],
          optionsJnsGuru: [],
          optionsUnitKerja: [],
        },
        alasan_mutasi: [],
        is_deleted: 0,
      });
    },
    deleteDetails(index) {
      this.form.details[index]["is_deleted"] = 1;
    },
    addTembusan() {
      this.form.tembusan.push({
        id: 0,
        tembusan: null,
        is_deleted: 0,
      });
    },
    deleteTembusan(index) {
      this.form.tembusan[index]["is_deleted"] = 1;
    },
    addAlasan(index) {
      const alasanContainer = this.form.details[index]["alasan_mutasi"];
      alasanContainer.push({
        id: 0,
        alasan: null,
        is_deleted: 0,
      });
    },
    deleteAlasan(index, index_alasan) {
      this.form.details[index]["alasan_mutasi"][index_alasan]["is_deleted"] = 1;
    },
    onChangesPegawai(index, value) {
      this.form.details[index].informasi_pegawai =
        value.v_detail_informasi_pegawai;
      this.form.details[index].nip = value.nip;

      this.form.details[index].ref_jenis_layanan_id = 6;
      const me = this;

      me.form.details[index]["upload_detail"] = [];

      const mutasi_upload = this.optionsJenisLayanan.find(({ id }) => id === 6);

      console.log(mutasi_upload);

      mutasi_upload.ref_persyaratan_pelayanan.forEach(async (element) => {
        let document = await me.$store.dispatch("d_identitas/document", {
          nip: me.form.details[index]["nip"],
          id: element.id,
        });
        me.form.details[index]["upload_detail"].push({
          id: null,
          upload_dokumen: document.file_path,
          ref_persyaratan_pelayanan_id: element.id,
          nama_dokumen: element.nama_dokumen,
          format_file: element.format_file,
          loadingFile: false,
        });

        me.form.details[index]["jabatan_mutasi"]["nip"] = value.nip;
      });
      this.componentKey += 1;
    },
    async onSearchJabatanUmum(index, search) {
      // loading(true);
      await this.searchJabatanUmum(search, this, index);
      // loading(false);
    },
    searchJabatanUmum: _.debounce(async (search, vm, index) => {
      let options = await vm.$store.dispatch(
        "master_jabatan_umum/autocomplete",
        search
      );
      if (search)
        vm.form.details[index]["jabatan_mutasi"]["optionsJabatanUmum"] =
          options;
    }, 500),
    async onSearchJabatanFungsional(index, search) {
      // loading(true);
      await this.searchJabatanFungsional(search, this, index);
      // loading(false);
    },
    searchJabatanFungsional: _.debounce(async (search, vm, index) => {
      let options = await vm.$store.dispatch(
        "master_jabatan_fungsional/autocomplete",
        search
      );
      if (search)
        vm.form.details[index]["jabatan_mutasi"]["optionsJabatanFungsional"] =
          options;
    }, 500),
    async onSearchJenisGuru(index, search) {
      // loading(true);
      await this.searchJenisGuru(search, this, index);
      // loading(false);
    },
    searchJenisGuru: _.debounce(async (search, vm, index) => {
      if (search) {
        let options = await vm.$store.dispatch(
          "master_jenis_guru/autocomplete",
          search
        );
        if (search)
          vm.form.details[index]["jabatan_mutasi"]["optionsJnsGuru"] = options;
      }
    }, 300),
  },
};
</script>